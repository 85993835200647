@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Didact+Gothic&family=Josefin+Sans:ital,wght@0,100..700;1,100..700&display=swap');


.didact-gothic-regular {
  font-family: "Didact Gothic", sans-serif;
  font-weight: 400;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Didact Gothic", sans-serif, 'Josefin Sans';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  overflow-x: hidden;
  overflow-y: auto;
}

body::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Edge */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#countries {
  background-size: 16px;
  background-position: calc(100% - 20px) 17px;
  background-repeat: no-repeat;
  background-color: rgb(13, 95, 247);
}

.codeMirror-custom {
  font-size: 14px;
}

.width-container {
  max-width: 1140px;
}

.hero-section::-webkit-scrollbar {
  display: none;
}

.navbar-transparent {
  background-color: transparent !important;
  transition: background-color 0.3s ease;
}

.navbar-scrolled {
  background-color: #188bc4 !important;
}

.s_btn {
  position: relative;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  padding: 16px 28px;
  border: none;
  border-radius: 3px;
  background-color: #1B2A46;
  transition: background-color 0.3s ease-in-out;
}

.s_btn::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(to right, #FF0000, #FFFF00);
  transition: width 0.8s ease;
}

.s_btn:hover {
  background-color: #152035;
}

.s_btn:hover::after {
  width: 100%;
}

.btn-icon {
  transition: transform 0.3s ease;
}

.btn:hover .button-icon {
  transform: translate(5px);
}

/* React Quill content(Blog) Styles */
.quill-content {
  font-family: inherit;
  line-height: 1.6;
  color: inherit;
  /* Ensure text color matches your theme */
}

/* Paragraphs */
.quill-content p {
  margin-bottom: 0.7rem;
}

/* Unordered and Ordered Lists */
.quill-content ul,
.quill-content ol {
  margin-left: 1.5rem;
  padding-left: 1.5rem;
  /* Ensure proper spacing for bullets and numbers */
}

/* Headings */
.quill-content h1 {
  font-size: 2.2rem;
  /* Largest heading */
  /* font-weight: bold; */
  /* margin: 1rem 0; */
}

.quill-content h2 {
  font-size: 1.9rem;
  /* Second largest heading */
  /* font-weight: bold; */
  /* margin: 0.8rem 0; */
}

.quill-content h3 {
  font-size: 1.75rem;
  /* Medium-sized heading */
  /* font-weight: bold; */
  /* margin: 0.6rem 0; */
}

.quill-content h4 {
  font-size: 1.5rem;
  /* Smaller heading */
  /* font-weight: bold; */
  /* margin: 0.5rem 0; */
}

.quill-content h5 {
  font-size: 1.25rem;
  /* Smaller heading */
  /* font-weight: bold; */
  /* margin: 0.4rem 0; */
}

.quill-content h6 {
  font-size: 1rem;
  /* Smallest heading */
  /* font-weight: bold; */
  /* margin: 0.3rem 0; */
}

/* Text Alignment (for justified content) */
.quill-content p,
.quill-content ul,
.quill-content ol {
  text-align: justify;
}

.quill-content {
  font-family: inherit;
  line-height: 1.6;
  color: inherit;
  /* Ensures it matches your theme */
}

/* Unordered Lists (Bullets) */
.quill-content ul {
  list-style-type: disc;
  /* Displays bullet points */
  margin-left: 2rem;
  /* Adds left margin for indentation */
  padding-left: 1.5rem;
  /* Ensures consistent spacing */
}

/* Ordered Lists (Numbers) */
.quill-content ol {
  list-style-type: decimal;
  /* Displays numbers */
  margin-left: 2rem;
  /* Adds left margin for indentation */
  padding-left: 1.5rem;
  /* Ensures consistent spacing */
}

/* List Items */
.quill-content ul li,
.quill-content ol li {
  margin-bottom: 0.5rem;
  /* Adds spacing between list items */
}

/* Optional: Nested Lists */
.quill-content ul ul,
.quill-content ol ol {
  list-style-type: circle;
  /* Different style for nested unordered lists */
  margin-left: 2rem;
  /* Indentation for nested lists */
}